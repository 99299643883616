import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Title from './Title';
import TextField from '@material-ui/core/TextField';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import request from './utils/request';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    maxWidth: '100vw',
    height: '100%',
    flexGrow: 1,
  },
  tabContent: {
    margin: 0,
    textAlign: 'center',
    borderRadius: 15,
    backgroundColor: 'white'
  },
  tabContainer: {
    flexGrow: 1,
    minHeight: 300,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.secondaryBg.default,
    borderRadius: 15
  },
  searchWidget: {
    position: 'absolute',
    top: 0,
    right: 24,
    zIndex: 100,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  sidebar: {
    maxWidth: 250,
    textAlign: 'left'
  }
}));
export default function StockByName(props) {
  const classes = useStyles();
  const [data, setData] = React.useState([]);
  const [filter, setFilter] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [selectedStock, setSelectedStock] = React.useState({});
  const fetchList = async () => {
    // fetch from db
    setLoading(true);
    setData([]);
    const filters = [
      'limit=100'
    ];
    if (filter?.length >= 3) {
      filters.push(`code=like.*${encodeURIComponent(filter)}*`);
    } else {
      return;
    };
    try {
      const path = `/stock?${filters.join('&')}`;
      const data = (await request({ path })).data;
      if (data) {
        setData(data);
      }
    } catch(e){
      console.log(e);
    }
    setLoading(false);
  }

  return (
    <div>
      <Title indicator="h3" variant="h6" color="secondary">
        <span>股票列表（2020.08.31）</span>
      </Title>
      <div>
        <div className={classes.sidebar}>
          <Paper
            component="div"
          >
            <TextField
              value={filter}
              onChange={(event) => {
                setFilter(event.target.value);
              }}
              label="搜索股票代码(3位)"
              variant="outlined"
            />
            <IconButton
              onClick={() => {
                fetchList();
              }}
              aria-label="search"
            >
              <SearchIcon />
            </IconButton>
          </Paper>
          {loading ? <CircularProgress color="inherit" size={20} /> : null}
          <List dense={true}>
            {(data || []).map((c, i) => {
              const selected = c.code === selectedStock?.code;
              return (
                <ListItem
                  button
                  selected={selected}
                  key={i}
                  onClick={() => setSelectedStock(c)}
                >
                  <ListItemText
                    primary={c.name}
                  />
                </ListItem>
              );
            })}
          </List>
        </div>
      </div>
    </div>
  );
}

