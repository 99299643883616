import React from 'react';
import { HashRouter, BrowserRouter, Route, Switch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Dashboard from './Dashboard';
import SwaggerUI from 'swagger-ui-react'
import 'swagger-ui-react/swagger-ui.css'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0
  }
}));

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route {...rest} render={props => (
    <Layout>
      <Component {...props} />
    </Layout>
  )} />
);


const renderSwagger = () => {
  return <SwaggerUI url="https://stats-api.tenty.co/api/" />;
};

export default function App() {
  const classes = useStyles();
  return (
    <Container maxWidth="xl" className={classes.root}>
      <BrowserRouter>
        <Switch>
          <Route path="/" exact={true} component={Dashboard}/>
          <Route path="/swagger" render={renderSwagger}/>
          <Route path="/:category/:code" component={Dashboard}/>
          <Route path="/:category" component={Dashboard}/>
        </Switch>
      </BrowserRouter>
    </Container>
  );
}
