import React from 'react';
import d3 from 'd3';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import Title from './Title';
import request from './utils/request';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
  },
  cardroot: {
    backgroundColor: theme.palette.primary.main,
  },
  cardtitle: {
    color: 'white',
    textAlign: 'left'
  },
  cardContentRoot: {
    padding: 0
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));

export default function IndicatorList(props) {
  const { parentCode, period, title, selectedIndicators, onSelectIndicator } = props;
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const classes = useStyles();
  const periodType = period === 'month' ? 'month' : 'year';

  // year_zb?parent_code=A0101
  React.useEffect(
    () => {
      async function fetchList() {
        // fetch from db
        setLoading(true);
        if (parentCode) {
          try {
            const filters = [];
            if (parentCode) {
              filters.push(`parent_code=eq.${encodeURIComponent(parentCode)}`);
            }
            const path = `/${periodType}_zb?${filters.join('&')}`;
            const data = (await request({ path })).data;
            if (data) {
              setData(data);
            }
          } catch(e){
            console.log(e);
          }
        }
        setLoading(false);
      }
      fetchList();
    }, [parentCode]
  );

  const colorArr = d3.scale.category20().range();
  const renderIndicatorList = (zb) => {
    return (
      <div>
        <Title indicator="h3" variant="h6" color="secondary">
          <span>{title || '细分列表'}</span>
        </Title>
        <div>
          <List dense={true}>
            {(data || []).map((c, i) => {
              const selected = selectedIndicators.includes(c.code);
              const bg = selected ? colorArr[i % 20] : 'white';
              return (
                <ListItem
                  button
                  selected={selected}
                  key={i}
                  style={{backgroundColor: bg}}
                  className={classes.listItem}
                  onClick={() => onSelectIndicator && onSelectIndicator(c, colorArr[i % 20])}
                >
                  <ListItemText
                    primary={c.name}
                  />
                </ListItem>
              );
            })}
          </List>
        </div>
      </div>
    );
  }

  return (
    <Grid container>
      {loading
        ? <CircularProgress />
        : renderIndicatorList()
      }
    </Grid>
  );
}

