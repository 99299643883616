import { yearIndicators, monthIndicators, rootIndicators } from '../constants';

const getIndicator = (period) => period === 'month' ? monthIndicators : yearIndicators;

const getNode = (indicators, code) => {
  const n = indicators[code];
  if (!n) return null;
  n.code = code;
  return n;
};

const getRoots = (period) => {
  const indicators = getIndicator(period);
  return (rootIndicators[period || 'year'] || []).map(i => getNode(indicators, i.code));
}

export function generateIndicatorChildren(code, period) {
  const roots = getRoots(period);
  if (!code || code.length < 3) return roots;
  const indicators = getIndicator(period);
  const root = indicators?.[code];
  if (!root) return roots;
  if (root && !root.children) {
    return [];
  }
  const codes = (root.children).split(',');
  const nodes = [];
  codes.forEach(c => { nodes.push(getNode(indicators, c)) });
  return nodes;
};

export function generateCurrentIndicator(code, period) {
  console.log(`code, ${code}, period: ${period}`);
  const indicators = getIndicator(period);
  return indicators[code];
}

export function generateIndicatorAncestors(code, period) {
  if (!code) return [];
  const indicators = getIndicator(period);
  const nodes = [];
  let ancestor = code;
  let node;
  while (ancestor.length >= 3) {
    node = getNode(indicators, ancestor);
    if (node) {
      nodes.push(getNode(indicators, ancestor));
    }
    ancestor = ancestor.substring(0, ancestor.length - 2);
  }
  return nodes;
};

export function generateTreeData(period) {
  const roots = getRoots(period);
  roots.map();
}

