import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#023d7d',
    },
    secondary: {
      main: '#00a1d6',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
    secondaryBg: {
      default: '#f1f1f1'
    },
    info: {
      main: '#dddddd'
    }
  },
  typography: {
    fontFamily: [
      'sans-serif',
      'Arial',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

export default theme;
