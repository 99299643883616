import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { API_URL } from './constants';
import downloadFileFromUrl from './utils/downloadFileFromUrl';


const useStyles = makeStyles((theme) => ({
  root: {
    height: 48
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  csvLink: {
    color: 'white',
    textDecoration: 'none'
  }
}));

export default function CsvDownloadButton({csvPath, filename}) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    loading: false,
  });

  const handleDownload = () => {
    setState({
      loading: true,
      csvData: ''
    });
    downloadFileFromUrl(`${API_URL}${csvPath}`, 'data.csv', {}, () => {
      setState({
        loading: false
      });
    });
  }

  return (
    <>
      <Button onClick={handleDownload} className={classes.root} variant="contained" color="primary">
       {state.loading ? 'Downloading ...' : 'Download'}
      </Button>
    </>
  );
}
