import React from 'react';
import d3 from 'd3';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Title from './Title';
import GraphCard from './GraphCard';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import request from './utils/request';
import { clone, uniq } from 'lodash';

const start = new Date('1990-01-01'); // monday, first day
const offsetDate = (i, code) => {
  if (code === 'moy' && i < 13) {
    return d3.time.format('%Y-%m')(d3.time.month.offset(start, i - 1));
  } else if (code === 'qoy' && i < 5) {
    return d3.time.format('%Y-%m')(d3.time.month.offset(start, 3 * (i - 1)));
  }
  return d3.time.format('%Y-%m-%d')(d3.time.day.offset(start, i - 1));
};

const categories = [
  { code: 'dow', name: '按星期',   parseFormat:'%Y-%m-%d', dateFormat: '%A', xtick: 5 },
  { code: 'dom', name: '按月日期', parseFormat:'%Y-%m-%d', dateFormat: '%d', xtick: 7 },
  { code: 'doy', name: '按年日期', parseFormat:'%Y-%m-%d', dateFormat: '%j', xtick: 7 },
  { code: 'moy', name: '按月份',   parseFormat:'%Y-%m', dateFormat: '%b', xtick: 12 },
  { code: 'qoy', name: '按季度',   parseFormat:'%Y-%m', dateFormat: '%m', xtick: 4 },
];

// data: [{year, x, count, plus_count, minus_count, x_desc}];
const dataFormatter = (list, code) => {
  const c = categories.find(i => i.code === code);
  if (c) {
    return list.map(i => {
      const {year, x, plus_count, minus_count, count} = i;
      const plus = Math.floor(plus_count / count * 10000) / 100;
      const minus = Math.floor(minus_count / count * 10000) / 100;
      return { year, date: offsetDate(x, code), plus, minus };
    });
  }
  return [];
}

const metaGenerator = (code) => {
  const c = categories.find(i => i.code === code);
  return {
    width: 600 - 40, // padding in the card
    height: 370, //don't change
    x: {
      printFormat: c?.dateFormat,
      dateFormat: c?.parseFormat,
      label: 'xLabelXXX',
      ticks: c?.xtick || 6
    },
    y: {
      label: '%',
      ticks: 5
    },
    label: ''
  };
}

export default function StockByName(props) {
  const [data, setData] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [selectedCode, setSelectedCode] = React.useState('');
  const [selectedYears, setSelectedYears] = React.useState([]);
  const fetchData = async (code) => {
    // fetch from db
    if (!code || data[code]) return;
    setLoading(true);
    const filters = ['order=year,x'];
    filters.push(`x_code=eq.${encodeURIComponent(code)}`);
    try {
      const path = `/report_view?${filters.join('&')}`;
      const list = (await request({ path })).data;
      if (list) {
        setData({...data, [code]: dataFormatter(list, code)});
      }
    } catch(e){
      console.log(e);
    }
    setLoading(false);
  }

  const toggleSelection = (code) => {
    setSelectedCode('');
    setSelectedYears([]);
    const dataKeys = Object.keys(data);
    if (dataKeys.indexOf(code) < 0) {
      fetchData(code);
    }
    setSelectedCode(code);
  }

  const toggleSelectYear = (year, color) => {
    const index = selectedYears.map(i => i.year).indexOf(year);
    if (index < 0) {
      setSelectedYears([...selectedYears, {year, color}]);
    } else {
      const cloned = clone(selectedYears);
      cloned.splice(index, 1);
      setSelectedYears(cloned);
    }
  }

  const renderEachUnit = (code, list) => {
    const meta = metaGenerator(code);
    return (
      <Grid item xs={12}>
        <GraphCard graphId={`${code}-${1}`} meta={meta} data={list}/>
      </Grid>
    );
  }

  const years = uniq((data[selectedCode] || []).map(d => d.year)).reverse();
            //{ Object.entries(data).map(([code, list], i) => renderEachUnit(code, list, i))}
  const dataFilterByYearAndCode = selectedYears.map(({year: selectedYear, color}) => (
    {
      cname: selectedYear,
      color: color,
      data: (data[selectedCode] || []).filter(d => d.year === selectedYear).map(({date, plus}) => ({date, value: plus}))
    }
  ));

  const colorArr = d3.scale.category20().range();

  return (
    <div>
      <Title indicator="h3" variant="h6" color="secondary">
        <span>按类别统计</span>
      </Title>
      <div>
        <Grid container>
          <Grid item xs={12} md={3}>
            <List dense={true}>
              {(categories || []).map((c, i) => {
                return (
                  <ListItem
                    button
                    selected={selectedCode === c.code}
                    key={i}
                    onClick={() => toggleSelection(c.code)}
                  >
                    <ListItemText
                      primary={c.name}
                    />
                  </ListItem>
                );
              })}
            </List>
          </Grid>
          <Grid item xs={12} md={1}>
            {loading ? <CircularProgress color="inherit" size={20} /> : null}
            <List dense={true}>
              {(years || []).map((y, i) => {
                const selected = selectedYears.map(i => i.year).includes(y);
                const bg = selected ? colorArr[i % 20] : 'white';
                return (
                  <ListItem
                    button
                    selected={selected}
                    key={i}
                    style={{backgroundColor: bg}}
                    onClick={() => toggleSelectYear(y, colorArr[i % 20])}
                  >
                    <ListItemText
                      primary={y}
                    />
                  </ListItem>
                );
              })}
            </List>
          </Grid>
          <Grid item xs={12} md={8}>
            {loading ? <CircularProgress color="inherit" size={20} /> : null}

            { selectedCode && selectedYears.length > 0 && renderEachUnit(selectedCode, dataFilterByYearAndCode) }
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

            //{ Object.entries(data).map(([code, list], i) => renderEachUnit(code, list, i))}
