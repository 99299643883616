import React from 'react';
import { withRouter } from "react-router";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Title from './Title';
import theme from './theme';
import DataTableV2 from './DataTableV2';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import StockByName from './StockByName';
import StockByTime from './StockByTime';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(0),
    backgroundColor: theme.palette.secondaryBg.default,
    borderRadius: 15
  },
  name: {
    backgroundColor: theme.palette.primary.main,
    width: 80,
    height: 70,
    fontSize: 35,
    lineHeight: 2,
    textAlign: 'center',
    verticalAlign: 'middle'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  container: {
    margin: 0,
    paddingBottom: 100,
    color: 'black',
    paddingTop: theme.spacing(1)
  }
}));

const useStylesNested = makeStyles((theme) => ({
  root: {
    position: 'relative',
    maxWidth: '100vw',
    height: '100%',
    flexGrow: 1,
  },
  tabContent: {
    margin: 0,
    textAlign: 'center',
    borderRadius: 15,
    backgroundColor: 'white'
  },
  tabContainer: {
    flexGrow: 1,
    minHeight: 300,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.secondaryBg.default,
    borderRadius: 15
  },
  searchWidget: {
    position: 'absolute',
    top: 0,
    right: 24,
    zIndex: 100,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  sidebar: {
    maxWidth: 250,
    textAlign: 'left'
  }
}));


const pageHeaderText = () => (
  <>
    <Grid container>
      <Title component="h2" variant="h4" color="secondary">
        <span>股票指标</span>
      </Title>
    </Grid>
  </>
);

function StockTab(props) {
  const [value, setValue] = React.useState(0);
  const classes = useStylesNested();
  const [selectedStock, setSelectedStock] = React.useState({});

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="时间" {...a11yProps(0)} />
          <Tab label="股票" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <div className={classes.tabContent}>
          <div className={classes.tabContainer}>
            <StockByTime />
          </div>
       </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div className={classes.tabContent}>
          <div className={classes.tabContainer}>
            <StockByName
              selectedCode={selectedStock?.code}
              onSelect={setSelectedStock}
            />
          </div>
        </div>
      </TabPanel>
    </div>
  );
}

function StockIndicators(props) {
  const classes = useStyles(theme);
  const tableRef = React.useRef(null)
  const [selected, setSelected] = React.useState([]);

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        {pageHeaderText()}
        <div style={{margin: 20}}/>
        <StockTab />
        <div style={{margin: 20}} />
        <div ref={tableRef}>
          <DataTableV2
            filters={selected}
          />
        </div>
      </div>
    </div>
  );
}

export default withRouter(StockIndicators);
