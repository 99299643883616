import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

const filterOptions = createFilterOptions({
  limit: 100
});

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 20,
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  }
}));

export default function SearchWidget({labelField, keyField, size, placeholder, onSearch, options}) {
  const classes = useStyles();
  const [filter, setFilter] = React.useState('');
  const height = size === 'sm' ? 40 : 50;

  return (
    <Paper
      component="div"
      className={classes.root}
      style={{height, marginTop: 4}}
    >
      <Autocomplete
        filterOptions={filterOptions}
        id="grouped-demo"
        options={(options || []).map((o) => ({code: o[keyField || 'code'], text: o[labelField || 'text']}))}
        getOptionLabel={(option) => option.text}
        style={{ width: '100%' }}
        onChange={(event, option) => {
          setFilter(option.code);
        }}
        renderInput={(params) =>
          <div ref={params.InputProps.ref}>
            <input style={{ width: '100%', height: 40, border: 'none' }} type="text" {...params.inputProps} />
          </div>
        }
      />
      <IconButton
        onClick={() => {
          onSearch && onSearch(filter);
        }}
        className={classes.iconButton}
        aria-label="search"
      >
        <SearchIcon />
      </IconButton>
    </Paper>
  );
}
