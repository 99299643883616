function handleError(message: string) {
  console.log(message);
 // ViviToaster.clear();
 // ViviToaster.show({ message, intent: Intent.DANGER });
}

async function formatHeader(response) {
  const text = await response.text();
  const body = text.split('/n');
  body.shift();
  // id,company_name,website,country_iso2,city,revenue,employee,credit_risk_rating_national_scores,naics_desc,naics_secondary_codes,sic_codes,advisor_address,in_world_compliance,nace_core_code,telephone_number,country_name,address,sector_desc,bank_advisor,supply_chain,supply_chain_type,component_desc,naics_2017,value_chain_stage
  const headers = "id,company_name,website,country_iso2,city,revenue,employee,credit_risk_rating_national_scores,naics_desc,naics_secondary_codes,sic_codes,advisor_address,in_world_compliance,nace_core_code,telephone_number,economy_name,address,sector_desc,bank_advisor,supply_chain,supply_chain_type,component_desc,naics_2017,value_chain_stage"
  body.unshift(headers);
  const blob = new Blob([body.join("\n")], { type: 'text/csv;charset=utf-8;' });
  console.log(text);
}

export default async function downloadFileFromUrl(url, defaultName, headers, onFinish) {
  const response = await fetch(url, { method: 'GET', headers: headers || {} });
  const contentDisposition = response.headers.get('Content-Disposition');
  const filename = contentDisposition ? contentDisposition.split('filename=')[1] : defaultName;
  if (response.ok) {
    const blob = await response.blob();
    // Create blob link to download
    // IE10+ : (has Blob, but not a[download] or URL)
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, filename);
    } else {
      const blobUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = filename;
      // Force download
      link.click();
    }
  } else {
    const message = `Error Message: ${response.statusText}`;
    handleError(message);
  }
  if (onFinish) {
    onFinish();
  }
}

