import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

export default function Title(props) {
  const { component, children, variant, color, align } = props;
  return (
    <Typography component={component || 'h2'} variant={variant || 'h6'} color={color || "secondary"} align={align || 'left'} gutterBottom>
      {children}
    </Typography>
  );
}

Title.propTypes = {
  children: PropTypes.node,
};
