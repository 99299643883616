import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Title from './Title';
import IndicatorList from './IndicatorList';
import TreeView from './TreeView';
import GraphGroup from './GraphGroup';
import theme from './theme';
import request from './utils/request';
import { clone } from 'lodash';

import { generateIndicatorAncestors, generateCurrentIndicator } from './utils/indicator';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    borderRadius: 8,
    backgroundColor: 'white'
  },
  productGroups: {
    [theme.breakpoints.up('sm')]: {
      height: 64,
      position: 'relative',
      textAlign: 'left',
    },
  },
  row: {
    height: 10,
    backgroundColor: 'red'
  },
  headers: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  divider: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
  },
  subTabHeaders: {
    backgroundColor: '#666666'
  },
  mainTabPanel: {
    padding: 0
  },
  mainTabContent: {
    margin: 0,
    padding: 0,
    //minHeight: 500,
    textAlign: 'center',
    backgroundColor: 'white'
  },
  listItem: {
    padding: 0,
    color: theme.palette.secondary.main
  }
}));

export default function StatsTab(props) {
  const classes = useStyles(theme);
  const subMenuRef = React.useRef(null);
  const { code, history, period, title } = props;
  const [expanded, setExpanded] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [selected, setSelected] = React.useState([code]);
  const [selectedIndicators, setSelectedIndicators] = React.useState([]);
  const [dataObj, setDataObj] = React.useState({});
  const currentNode = generateCurrentIndicator(selected, period);
  const periodType = period === 'month' ? 'month' : 'year';

  // General scroll to element function
  const scrollToRef = (ref) => {
    window.scrollTo(0, ref.current.offsetTop);
  }

  const hasSecondary = currentNode && !currentNode.children;

  const fetchCount = async (indicatorCode, color) => {
    // fetch from db
    if (dataObj[indicatorCode]) return;
    setLoading(true);
    if (indicatorCode) {
      try {
        const filters = [
          `select=${periodType}_data(*),code,cname,exp,unit,memo`,
          `code=eq.${indicatorCode}`

        ];
        const path = `/${periodType}_zb?${filters.join('&')}`;
        const data = (await request({ path })).data;
        if (data && data.length > 0) {
          const formatted = data.map((zb_data) => {
            const {code, cname, value, unit, exp, memo, year_data, month_data} = zb_data;
            const rawData = period === 'month' ? month_data : year_data;
            const formatedData = (rawData || []).filter(i => i.hasdata).map(i => ({ date: i.sj_code, value: i.value}));
            return ({code, cname, value, unit, exp, memo, data: formatedData, color});
          });
          setDataObj({...dataObj, [indicatorCode]: formatted[0]});
          // dataObj[indicatorCode] = formatted[0];
        }
      } catch(e){
        console.log(e);
      }
    }
    setLoading(false);
  }

  React.useEffect(
    () => {
      setSelected(code);
      const ancestors = generateIndicatorAncestors(code, period);
      const expandedIds = ancestors.map(n => n.code);
      setExpanded(expandedIds);
      setSelectedIndicators([]);
      if (hasSecondary) scrollToRef(subMenuRef);
    }, [code]
  );

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (event, nodeIds) => {
    history.push(`/${period === 'year' ? 'year' : 'month'}/${nodeIds}`);
  };

  const generateSelectedDataObj = () => {
    const selectedDataObj = {};
    selectedIndicators.forEach(code => dataObj[code] ? selectedDataObj[code] = dataObj[code] : '');
    return selectedDataObj;
  }

  const handleSelectIndicator = (indicatorObj, color) => {
    const indicatorCode = indicatorObj?.code;
    const index = selectedIndicators.indexOf(indicatorCode);

    if (index < 0) {
      setSelectedIndicators([...selectedIndicators, indicatorCode]);
      fetchCount(indicatorCode, color);
    } else {
      const cloned = clone(selectedIndicators);
      cloned.splice(index, 1);
      setSelectedIndicators(cloned);
    }
  }

  return (
    <div className={classes.root}>
      <Box p={3}>
        <Grid container spacing={3}>
          <Grid item style={{ textAlign: 'left', width: 300 }} md={3} xs={12}>
            <Title indicator="h2" variant="h4" color="secondary">
              <span>{title || '指标'}</span>
            </Title>
            <div className={classes.mainTabContent}>
              <TreeView
                key={period}
                period={period || 'year'}
                expandedIds={expanded}
                selectedIds={selected}
                onToggle={handleToggle}
                onSelect={handleSelect}
              />
            </div>
          </Grid>

          {hasSecondary && (
            <Grid item md={3} xs={12} ref={subMenuRef}>
              <IndicatorList
                parentCode={selected}
                period={period}
                selectedIndicators={selectedIndicators}
                onSelectIndicator={handleSelectIndicator}
              />
            </Grid>
          )}
          {loading && <CircularProgress/> }
          {!loading && hasSecondary && selectedIndicators && (
            <Grid item md={6} xs={12} >
              <GraphGroup title={selected} dataObj={generateSelectedDataObj()} indicator={selectedIndicators} period={period} />
            </Grid>
          )}
        </Grid>
      </Box>
    </div>
  );
}
