import React, { useLayoutEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import GraphCard from './GraphCard';

function useWindowSize(ref) {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([ref.current.offsetWidth, ref.current.offsetHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

export default function GraphGroup(props) {
  const ref = useRef(null);
  const [width,] = useWindowSize(ref);
  const { period, dataObj } = props;
  // const classes = useStyles();
  const dateFormat = period === 'month' ? '%Y%m' : '%Y';
  const xLabel = period === 'month' ? '月' : '年';

  // year_zb?code=like.A0101%&select=year_data(*),code,cname,exp
  //
  const metaGenerator = (unit) => {
    return {
      width: width - 40, // padding in the card
      height: 370, //don't change
      x: {
        dateFormat,
        label: xLabel,
        ticks: 'auto'
      },
      y: {
        label: unit,
        ticks: 5
      },
      label: ''
    };
  }

  const renderEachUnit = (unit, data, i) => {
    const meta = metaGenerator(unit);
    return (
      <Grid item xs={12}>
        <GraphCard graphId={`${unit}-${i}`} meta={meta} data={data}/>
      </Grid>
    );
  }

  const groupedByUnit = {};
  Object.values(dataObj).forEach(o => {
    const unit = o.unit;
    if (groupedByUnit[unit]) {
      groupedByUnit[unit].push(o);
    } else {
      groupedByUnit[unit] = [o];
    }
  });

  return (
    <Grid container ref={ref}>
      { Object.entries(groupedByUnit).map(([unit, data], i) => renderEachUnit(unit, data, i))}
    </Grid>
  );
}
