import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

import YearIndicators from './YearIndicators';
import MonthIndicators from './MonthIndicators';
import StockIndicators from './StockIndicators';
import SearchWidget from './SearchWidget';
import GlobalResult from './ResultPage';
import yearOptions from './indicators/year_group.json';
import monthOptions from './indicators/month_group.json';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    maxWidth: '100vw',
    height: '100%',
    flexGrow: 1,
    backgroundColor: 'lightblue'
  },
  tabContent: {
    margin: 0,
    textAlign: 'center',
    borderRadius: 15,
    backgroundColor: 'white'
  },
  searchWidget: {
    position: 'absolute',
    top: 0,
    right: 24,
    zIndex: 100,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  }
}));

export default function TabContainer(props) {
  const categories = ['year', 'month', 'stock'];
  const { defaultTab, history } = props;
  const value = defaultTab;
  const classes = useStyles();
  const [filter, setFilter] = React.useState('');
  const period = categories[defaultTab];

  const handleChange = (event, newValue) => {
    history.push(`/${categories[newValue]}`);
  };

  const handleFuzzySearch = (code) => {
    if (code) {
      history.push(`/${period}/${code}`);
    }
  }

  const options = period === 'year' ? yearOptions : monthOptions;

  return (
    <div className={classes.root}>
      <div className={classes.searchWidget}>
        <SearchWidget options={options} size="sm" placeholder="Global Search (minimum 3 letters)" onSearch={(v) => handleFuzzySearch(v || '')} />
      </div>
      <AppBar position="static">
        <Tabs value={defaultTab} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="年度数据" {...a11yProps(0)} />
          <Tab label="月度数据" {...a11yProps(1)} />
          <Tab label="A股数据" {...a11yProps(2)} />
          <Tab style={{display: 'none'}} label="" {...a11yProps(10)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <div className={classes.tabContent}>
          <YearIndicators />
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div className={classes.tabContent}>
          <MonthIndicators />
       </div>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <div className={classes.tabContent}>
          <StockIndicators />
       </div>
      </TabPanel>
      <TabPanel value={value} index={10}>
        <div className={classes.tabContent}>
         {filter.length > 2 && <GlobalResult key={filter} filter={filter}/> }
        </div>
      </TabPanel>
    </div>
  );
}
