import React from 'react';
import { withRouter } from "react-router";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Title from './Title';
import theme from './theme';
import StatsTab from './StatsTab';
import DataTableV2 from './DataTableV2';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
    },
    backgroundColor: theme.palette.secondaryBg.default,
    borderRadius: 15
  },
  name: {
    backgroundColor: theme.palette.primary.main,
    width: 80,
    height: 70,
    fontSize: 35,
    lineHeight: 2,
    textAlign: 'center',
    verticalAlign: 'middle'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  container: {
    margin: 0,
    paddingBottom: 100,
    color: 'black',
    paddingTop: theme.spacing(1)
  }
}));

const pageHeaderText = (code) => (
  <>
    <Grid container>
      <Title component="h2" variant="h4" color="secondary">
        <span>年度指标</span>
      </Title>
    </Grid>
  </>
);

function YearIndicators(props) {
  const { history, match: { params: { code }} } = props;
  const classes = useStyles(theme);
  const tableRef = React.useRef(null)
  const [selected, setSelected] = React.useState([]);

  // General scroll to element function
  const scrollToRef = (ref) => {
    console.log(ref);
    window.scrollTo(0, ref.current.offsetTop);
  }

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        {pageHeaderText(code)}
        <div style={{margin: 20}}/>
        <StatsTab
          period="year"
          code={code}
          selected={selected}
          history={history}
        />
        <div style={{margin: 20}} />
        <div ref={tableRef}>
          <DataTableV2
            filters={selected}
          />
        </div>
      </div>
    </div>
  );
}

export default withRouter(YearIndicators);
