import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import { generateIndicatorChildren } from './utils/indicator';

const useStyles = makeStyles({
  root: {
    textAlign: 'left',
    flexGrow: 1,
    maxWidth: 400,
  },
});

export default function RecursiveTreeView(props) {
  const { period, expandedIds, selectedIds = [], onToggle, onSelect } = props;
  const classes = useStyles();
  const roots = generateIndicatorChildren('', period);

  const renderItem = (node) => (
    <TreeItem key={`${period}-${node.code}`} nodeId={node.code} label={node.text}>
      {node.children ? renderTree(generateIndicatorChildren(node.code, period)) : null}
    </TreeItem>
  );

  const renderTree = (nodes) => {
    return nodes.map(node => renderItem(node));
  };

  return (
    <TreeView
      className={classes.root}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpanded={['root']}
      defaultExpandIcon={<ChevronRightIcon />}
      expanded={expandedIds}
      selected={selectedIds}
      onNodeToggle={onToggle}
      onNodeSelect={onSelect}
    >
      {renderTree(roots)}
    </TreeView>
  );
}
