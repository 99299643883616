import React from 'react';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     // flexGrow: 1,
//     // backgroundColor: 'lightblue'
//     backgroundColor: 'black',
//     padding: 15,
//     borderRadius: 5,
//     textAlign: 'center',
//     width: 'auto',
//     height: 'auto',
//     color: 'white',
//     position: 'absolute'
//   },
//   // tabContent: {
//   //   margin: 0,
//   //   textAlign: 'center',
//   //   borderRadius: 15,
//   //   backgroundColor: 'white'
//   // }
// }));

export default class HoverObject extends React.Component{
  constructor(props) {
    super(props);
    this.text = props.text;
    // console.log(props.text);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.state = {
      isHovering: false
    };
  }

  handleMouseEnter(){
    this.setState({
      isHovering: true
    })
  }
  handleMouseLeave(){
    this.setState({
      isHovering: false
    })
  }
  // handleMouseHover() {
  //   this.setState(this.toggleHoverState);
  // }

  // toggleHoverState(state) {
  //   return {
  //     isHovering: !state.isHovering,
  //   };
  // }

  render() {
    const styles = {
      backgroundColor: '#023d7d',
      padding: 15,
      borderRadius: 5,
      textAlign: 'center',
      width: '400px',
      height: 'auto',
      color: 'white',
      position: 'absolute',
      bottom: 0,
      right: '15%',
      zIndex:1000
    };
    return (
      <span>
        <span
          onMouseOver={this.handleMouseEnter}
          onMouseOut={this.handleMouseLeave}
        >
         {this.text}
        </span>
        {
          this.state.isHovering &&
          <div style={styles}>
           The data provided is compiled from multiple publicly available sources.
          </div>
        }
      </span>
    );
  }
}
