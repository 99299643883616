// const API_URL = 'http://52.77.191.142:8080/api'; // local development
// const API_URL = 'http://adbpublicstg.prod.acquia-sites.com/multimedia/scf/scf.api.php?api_url=http://52.77.191.142/api'; // test server
// const API_URL = 'https://www.adb.org/multimedia/scf/scf.api.php?api_url=http://52.77.191.142/api'; // production server

import yearIndicators from './indicators/year.json';
import monthIndicators from './indicators/month.json';
import rootIndicators from './indicators/root.json';

const API_URL=process.env.REACT_APP_API_URL || 'https://stats-api.tenty.co/api' || 'https://cheeger.com/api';

export {
  API_URL,
  yearIndicators,
  monthIndicators,
  rootIndicators
};
