import React from 'react';
import { withRouter } from "react-router";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';

import TabPanel from './TabPanel';
import Title from './Title';
import HoverObject from './HoverRender';

function FooterItems({classes}) {
  const list = [
    { name: '国家统计局', link: 'https://data.stats.gov.cn/' },
    { name: '世界银行', link: 'https://data.worldbank.org/' },
    { name: 'Alba主页', link: 'https://cheeger.com/' }
  ]
  return (
    <>
    <Grid container>
      <Grid item xs={12} md={10}>
        <Grid container>
          <Typography className={classes.footerHeader} variant="body2" align="left">
            数据来源
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          {list.map(s => (
            <Grid item key={s.name}>
              <Typography className={classes.footerItem} variant="body2" align="left">
                <Link href={s.link} target="_blank" rel="noopener">
                 {s.name}
                </Link>
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12} md={2}>
      <div style={{position: 'relative'}}>
        <Grid container>
          <Typography className={classes.footerHeader} variant="body2" align="left">
            <HoverObject text="Open Source"></HoverObject>
          </Typography>
        </Grid>
        <Grid container>
          <Typography className={classes.footerItem} variant="body2" align="left">
            <Link href="mailto:alba@tenty.co" target="_blank" rel="noopener">
               联系我们
            </Link>
          </Typography>
        </Grid>
      </div>
      </Grid>
    </Grid>
    </>
  );
}



const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  disclaimer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    // paddingLeft: 88,
    // paddingRight: 88,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
    },
  },
  content: {
    flexGrow: 1
  },
  footer: {
    margin: 0,
    paddingLeft: 88,
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  footerItem: {
    '& > a': {
      color: 'white',
    }
  },
  footerHeader: {
    fontWeight: 'bold'
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  floatButton: {
    zIndex: 200,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: 'white',
    position: 'fixed',
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: {
      minHeight: 30,
      padding: 10,
      fontSize: 15,
      borderWidth: 1,
      bottom: 10,
      right: 10,
      borderRadius: 40,
    },
    [theme.breakpoints.up('sm')]: {
      minHeight: 30,
      paddingTop: 20,
      paddingBottom: 20,
      paddingLeft: 40,
      paddingRight: 40,
      fontSize: 20,
      borderWidth: 1,
      bottom: 80,
      right: 50,
      borderRadius: 40,
    },
  }
}));

const getTabIndex = (category) => {
  switch(category) {
    case 'year': return 0;
    case 'month': return 1;
    case 'stock': return 2;
    default: return 0;
  }
}

function Dashboard(props) {
  const { history, match: { params: { category }} } = props;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <main className={classes.content}>
        <TabPanel history={history} defaultTab={getTabIndex(category)}/>
        <div className={classes.disclaimer}>
          <Grid container>
            <Title component="h4" variant="h4" color="primary">
              <span>免责声明</span>
            </Title>
          </Grid>
          <Title component="p" variant="body1" color="primary">
             我个人没有确认和验证过上述信息, 因此他们可能不是真实的, 或者不是完全真实的。
          </Title>

          <Title component="p" variant="body1" color="primary">
          </Title>
        </div>
        <Container maxWidth={false} className={classes.footer}>
          <FooterItems classes={classes}/>
        </Container>
      </main>
    </div>
  );
}

export default withRouter(Dashboard);
