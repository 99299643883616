import { API_URL } from '../constants';

const checkStatus = async (response) => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else if (response.status === 401) {
    const error = new Error("401");
    error.response = await response.json();
    throw error;
  } else if (response.status === 404) {
    const error = new Error("404");
    error.response = await response.json();
    throw error;
  } else if (response.status >= 400 && response.status < 500) {
    const error = new Error("40X");
    error.response = await response.json();
    throw error;
  } else {
    const error = new Error("5XX");
    error.response = await response.json();
    throw error;
  }
}

async function request({ url, path, method, body }) {

  const parseJSON = async (response) => {
    const data = await response.json();
    const range = response.headers.get('Content-Range') || response.headers.get('Contents-Range');
    const count = range ? parseInt(range.split('/')[1]) : 0;
    return { data, count };
  }

  const formattedUrl = url || `${API_URL}${path}`;

  return fetch(formattedUrl, {
    method: method || 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Prefer': 'count=exact'
    },
    body: JSON.stringify(body)
  }).then(checkStatus).then(parseJSON).catch(console.log);
}

export default request;
