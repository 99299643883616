import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import LineChart from './LineChartWithData';
import HelpIcon from '@material-ui/icons/Help';
import Tooltip from '@material-ui/core/Tooltip';
import { flatten } from 'lodash';


const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
  },
  cardroot: {
    backgroundColor: theme.palette.primary.main,
  },
  cardtitle: {
    color: 'white',
    textAlign: 'left'
  },
  cardContentRoot: {
    padding: 20,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));


export default function GraphCard(props) {
  const { graphId, meta, data } = props;
  const classes = useStyles();
  const tooltip = flatten((data || []).map(({color, exp, memo}) => [{color, text: exp}, {color, text: memo}]));
  const title = (data || []).map(i => i.cname).join(' / ');
  const l = 50;
  const p = title.length > l ? title.substring(0, l) + '...' : title;
  const s = title.length > l ? `${title.substring(l)}` : '';


  const header = (
    <>
      <span style={{paddingRight: 5}}>{p}</span>
      { s && (
        <Tooltip title={s} aria-label="help">
          <HelpIcon />
        </Tooltip>
      )}
    </>
  );


  return (
    <Card className={classes.root}>
      {title && (
        <CardHeader classes={{
          root: classes.cardroot,
          title: classes.cardtitle
        }} title={header}/>
      )}
      <CardContent classes={{root: classes.cardContentRoot}}>
        <Typography variant="body2" component="p">
          <LineChart graphId={graphId} meta={meta} data={data}/>
        </Typography>
        {tooltip.map(t => (
          <Typography key={t.text} variant="body2" component="p" align="left" style={{color: t.color}}>
            {t.text}
          </Typography>
        ))}
      </CardContent>
    </Card>
  );
}
