import React, { useRef } from 'react';
import d3 from 'd3';
import CircularProgress from '@material-ui/core/CircularProgress';
import request from './utils/request';
import drawGraph from './utils/datelinev3';
import './utils/datelinev2.css';

export default function LineChartWithData(props) {
  const { data, graphId, meta } = props;
  React.useEffect(
    () => {
      const defaultMeta = {
        width: 400,
        height: 370,
        x: {
          dateFormat: '%Y',
          dataType: 'date',
          label: '年',
          ticks: 10
        },
        y: {
          label: 'y',
          ticks: 5
        },
        label: ''
      };
      if (data) {
        drawGraph(graphId, data.map(d => d.color), data.map(d => d.data), meta || defaultMeta);
      }
    }, [data, meta.width]
  );

  return (
    <div>
      <div className="linechart" id={graphId}></div>
    </div>
  )
}
