import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CircularProgress from '@material-ui/core/CircularProgress';
import HelpIcon from '@material-ui/icons/Help';
import Link from '@material-ui/core/Link';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import Pagination from '@material-ui/lab/Pagination';
import SearchWidget from './SearchWidget';
import CsvDownloadButton from './CsvDownloadButton';
import request from './utils/request';

const PAGE_SIZE = 20;
const StyledTableCell = withStyles((theme) => ({
  head:{
    backgroundColor: theme.palette.primary.main,
    color: 'white'
  },
  body: {
    fontSize: 14,
  }
}))(TableCell);

const columns = [
  { header: 'Company name*', column: 'company_name' },
  {
    header: 'Website*',
    column: 'website',
    render: (record) => {
      const website = record['website'];
      return website
        ? <Link href={`//${website}`} target="_blank" rel="noopener">{website}</Link>
        : 'n/a';
    }
  },
  { header: 'Economy', column: 'country_name' },
  { header: 'City', column: 'city' },
  { header: 'Component', column: 'component_desc' },
  { header: 'Phone', column: 'telephone_number' },
  { header: 'Revenue ($US 1000s)', column: 'revenue' },
  {
    header: 'Employees',
    column: 'employee',
    render: (record) => record.employee || 'n/a'
  },
  { header: 'Bank advisor', column: 'bank_advisor' },
  { header: 'Relevancy', column: 'relevancy', tooltip: 'Relevancy is the closeness of a company to the supply chain it is listed in. 3 is closely associated, 2 is moderately associated and 1 is weakly associated. The method is based on manual checking and an emerging AI model that scores the companies products in relation to their Covid 19 supply chain relevance.' },
  { header: 'ISO', column: 'regulator' },
];

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const styles = (theme) => ({
  tableContainer: {
    maxHeight: 900
  },
  table: {
  },
  seeMore: {
    marginTop: 5,
    marginBottom: 5
  },
  headerRoot: {
    color: 'white !important',
  },
  icon: {
    margin: 0,
    fontSize: 25,
    color: 'white !important',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
});


class DataTableV2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      orderBy: 'relevancy',
      asc: true,
      filter: '',
      currentPage: 1,
      rows: [],
      count: -1
    }
  }

  generateJsonPath = (pageNumber) => {
    const { filters } = this.props;
    console.log('----');
    console.log(filters);
    const { filter, orderBy, asc } = this.state;
    const { component, product, country, stage } = filters || {};
    const select = (columns || []).map(e => e.column);
    const offset = (pageNumber - 1) * PAGE_SIZE;
    const limit = PAGE_SIZE;
    const queries = [
      `offset=${offset}`,
      `limit=${limit}`,
      `select=id,${select.join(',') || 'id,company_name,website,country_name,address'}`,
      `order=${orderBy}.${asc ? 'asc' : 'desc'}`
    ];
    if (component) {
      queries.push(`component_desc=eq.${encodeURIComponent(component)}`);
    }
    if (stage && stage !== 'All Stages') {
      queries.push(`value_chain_stage=eq.${encodeURIComponent(stage)}`);
    }
    if (product && product !== 'All Products') {
      queries.push(`supply_chain=eq.${encodeURIComponent(product)}`);
    }
    if(country) {
      queries.push(`country_iso2=eq.${encodeURIComponent(country)}`);
    }
    if (filter) {
      const words = filter.toString().toLowerCase().trim().split(/\s+/).join(' & ');
      queries.push(`search_term=${encodeURIComponent(words)}`);
    }
    const baseUrl = filter ? '/rpc/fuzzy_matched_supply_chains' : '/supply_chains';
    return `${baseUrl}?${queries.join('&')}`;
  };

  generateCsvPath = () => {
    const { filters } = this.props;
    const { filter, orderBy, asc } = this.state;
    const { component, product, country, stage } = filters || {};
    const select = (columns || []).map(e => e.column);
    const queries = [
      `order=${orderBy}.${asc ? 'asc' : 'desc'}`
    ];
    if (component) {
      queries.push(`component_desc=eq.${encodeURIComponent(component)}`);
    }
    if (stage && stage !== 'All Stages') {
      queries.push(`value_chain_stage=eq.${encodeURIComponent(stage)}`);
    }
    if (product && product !== 'All Products') {
      queries.push(`supply_chain=eq.${encodeURIComponent(product)}`);
    }
    if(country) {
      queries.push(`country_iso2=eq.${encodeURIComponent(country)}`);
    }
    if (filter) {
      const words = filter.toString().toLowerCase().trim().split(/\s+/).join(' & ');
      queries.push(`search_term=${encodeURIComponent(words)}`);
    }
    const baseUrl = filter ? '/csv/rpc/fuzzy_matched_supply_chains' : '/csv/supply_chains';
    return `${baseUrl}?${queries.join('&')}`;
  };

  toggleSort = (selectedOrderBy) => {
    const { orderBy, asc } = this.state;
    if (orderBy === selectedOrderBy) {
      this.setState({ asc: !asc }, () => this.fetchPage(1));
    } else {
      this.setState({ orderBy: selectedOrderBy, asc: true },  () => this.fetchPage(1));
    }
  };

  fetchPage = (pageNumber) => {
    console.log('--');
    console.log(this.props.filters);
    this.setState({loading: true}, async () => {
      try {
        const path = this.generateJsonPath(pageNumber);
        console.log(path);
        const json = await request({ path });
        this.setState({
          loading: false,
          rows: json.data,
          count: json.count
        });
      } catch (e) {
        console.log(e);
        this.setState({
          loading: false,
          rows: [],
          count: 0
        });
      }
    });
  };

  componentDidMount() {
    const filters = this.props.filters;
    if (filters && filters.product && filters.stage) {
      this.fetchPage(1);
    }
  }

  componentDidUpdate(prevProps) {
    const prevFilters = prevProps.filters || {};
    const { product, component, country, stage } = this.props.filters || {};
    if (product && stage && (prevFilters.product !== product || prevFilters.component !== component || prevFilters.country !== country || prevFilters.stage !== stage)) {
      this.fetchPage(1);
    }
  }

  handleFuzzySearch = (filter) => {
    this.setState({filter}, () => {
      this.fetchPage(1);
    });
  }

  handlePageChange = (p) => {
    this.setState({currentPage: p}, () => {
      this.fetchPage(p);
    });
  }

  renderTableBody = () => {
    const { loading, rows } = this.state;
    if (loading) {
      return <></>;
    }
    return (
      <TableBody>
        {rows.map((row, i) => (
          <StyledTableRow key={i}>
            {(columns || []).map(c => {
              const column = c.column;
              const render = c.render;
              if (render) {
                return <StyledTableCell key={column}>{render(row)}</StyledTableCell>;
              } else {
                return <StyledTableCell key={column}>{row[column]}</StyledTableCell>;
              }
            })}
          </StyledTableRow>
        ))}
      </TableBody>
    );
  };

  render() {
    const { filters, classes } = this.props;
    const { loading, currentPage, filter, orderBy, asc, count } = this.state;
    const { product } = filters || {};
    if (!product) {
      return <></>;
    }

    return (
      <div>
        <div style={{float: 'left'}}>
          <SearchWidget onSearch={(v) => this.handleFuzzySearch(v)} />
        </div>
        <div style={{float: 'right'}}>
          <CsvDownloadButton csvPath={this.generateCsvPath()} filename="test.csv" />
        </div>

        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table stickyHeader className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                {columns.map((c, i) => {
                  const orderDirection = asc ? 'asc' : 'desc';
                  return (
                    <StyledTableCell
                      key={i}
                      sortDirection={orderBy ? orderDirection : false}
                    >
                      <TableSortLabel
                        classes={{
                          root: classes.headerRoot,
                          icon: classes.icon
                        }}
                        IconComponent={ArrowDropDownIcon}
                        active={orderBy === c.column}
                        direction={asc ? 'asc' : 'desc'}
                        onClick={() => this.toggleSort(c.column)}
                      >
                        {c.header}
                        {c.column === orderBy ? (
                          <span className={classes.visuallyHidden}>
                            {asc ? 'sorted descending' : 'sorted ascending'}
                          </span>
                        ) : null}
                        {c.tooltip && (
                          <Tooltip title={c.tooltip} aria-label="help">
                            <HelpIcon style={{width: 18}}/>
                          </Tooltip>
                        )}
                      </TableSortLabel>
                    </StyledTableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            {this.renderTableBody()}
          </Table>
        </TableContainer>
        <div className={classes.seeMore}>
          { loading
            ? <CircularProgress/>
            : <Pagination page={currentPage} count={Math.ceil(count / PAGE_SIZE)} variant="outlined" shape="rounded" onChange={(e, p) => this.handlePageChange(p)}/>
          }
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(DataTableV2);

